import {
	Link,
	NavigateFunction,
	useFetcher,
	useNavigate,
} from '@remix-run/react'
import { loader as searchLoader } from '#app/routes/_app+/search+/$searchTerm.tsx'
import React, { useEffect } from 'react'
import { getSearchType } from './search'
import {
	BanknoteIcon,
	CalendarIcon,
	HandCoins,
	IndianRupee,
	Network,
	PlusCircleIcon,
	ReceiptIndianRupee,
	UserRoundPlus,
	UsersRound,
} from 'lucide-react'
import { cn, useDebounce } from './misc'
import {
	CommandInput,
	CommandList,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandSeparator,
} from '#app/components/ui/command.js'
import { buttonVariants } from '#app/components/ui/button.js'
import { useCommandState } from 'cmdk'
import { requireUserWithPermission } from './permissions.server'
import { userHasRoles, useUser } from './user'

export function useCommand({
	defaultCommand,
	search: controlledSearch,
	setSearch: controlledSearchHandler,
}: {
	defaultCommand: string
	search?: string
	setSearch?: (search: string) => void
}) {
	const navigate = useNavigate()
	const fetcher = useFetcher<typeof searchLoader>({
		key: 'command-loader',
	})

	const students =
		fetcher.data?.status === 'idle' && 'students' in fetcher.data
			? fetcher.data.students
			: []
	const users =
		fetcher.data?.status === 'idle' && 'users' in fetcher.data
			? fetcher.data.users
			: []

	const [searchValue, setSearchValue] = React.useState(defaultCommand)

	const search = controlledSearch ?? searchValue
	const setSearch = controlledSearchHandler ?? setSearchValue

	const isSearch = getSearchType(search)

	const actions = getCommandActions({
		navigate,
		setSearch,
	})

	useEffect(() => {
		if (isSearch) {
			fetcher.load(`/search/${search}`)
		}
	}, [defaultCommand])

	const handleSearch = useDebounce((search: string) => {
		if (isSearch) {
			fetcher.load(`/search/${search}`)
		}
	}, 400)

	return {
		fetcher,
		students,
		users,
		search,
		setSearch,
		isSearch,
		handleSearch,
		actions,
	}
}

export function CommandItems({
	closeDialog,
	defaultCommand,
	search: controlledSearch,
	setSearch: controlledSearchHandler,
}: {
	defaultCommand: string
	search?: string
	setSearch?: (search: string) => void
	closeDialog?: () => void
}) {
	const {
		students,
		users,
		search,
		setSearch,
		isSearch,
		handleSearch,
		actions,
		fetcher,
	} = useCommand({
		defaultCommand,
		search: controlledSearch,
		setSearch: controlledSearchHandler,
	})

	const searchSync = useCommandState((state) => state.search)

	const user = useUser()

	const renderActionGroup = ([groupKey, group]: [string, any]) => {
		if (group.roles && !userHasRoles(user, group.roles)) return null
		return (
			<>
				<CommandGroup key={groupKey} heading={groupKey}>
					{group.actions.map((action: any) => {
						if ('roles' in action && !userHasRoles(user, action.roles)) {
							return null
						}

						return (
							<CommandItem
								onSelect={(value) => {
									action.action.onSelect(value)
									closeDialog?.()
								}}
								key={action.action.text}
								keywords={action.action.keywords ?? []}
							>
								{action.action.icon}
								{action.action.text}
							</CommandItem>
						)
					})}
				</CommandGroup>
				<CommandSeparator />
			</>
		)
	}

	return (
		<>
			<CommandInput
				className={cn('rounded-b-none')}
				placeholder="Type a command or search..."
				value={search}
				onValueChange={(s) => {
					setSearch(s)
					handleSearch(s)
				}}
			/>
			<CommandList className={cn('border-t-none rounded-b-md border')}>
				{!isSearch ? <CommandEmpty>No results found.</CommandEmpty> : null}

				{isSearch &&
					students.length > 0 &&
					students.map((student) => (
						<div className="flex w-full items-center justify-between border p-2">
							<div className="flex items-center gap-4">
								<p>{student.name}</p>
								<p className="text-sm text-muted-foreground">
									{student.classSections[0]?.classSection.class.class}-
									{student.classSections[0]?.classSection.division}
								</p>
								<p className="text-xs">{student.admissionNumber}</p>
							</div>
							<div className="flex gap-4">
								<Link
									className={buttonVariants({
										variant: 'default',
									})}
									to={`/students/${student.id}`}
									onClick={() => {
										closeDialog?.()
									}}
								>
									View
								</Link>
								<Link
									className={buttonVariants({
										variant: 'default',
									})}
									to={`/students/${student.id}/fee-payment`}
									onClick={() => {
										closeDialog?.()
									}}
								>
									Pay Fees
								</Link>
							</div>
						</div>
					))}
				{isSearch &&
					users.length > 0 &&
					users.map((user) => (
						<div className="flex w-full items-center justify-between border p-2">
							<div className="flex items-center gap-4">
								<p>{user?.name}</p>
							</div>
						</div>
					))}
				{Object.entries(actions).map((actionGroup) =>
					renderActionGroup(actionGroup),
				)}
			</CommandList>
		</>
	)
}

export function getCommandActions({
	navigate,
	setSearch,
}: {
	navigate: NavigateFunction
	setSearch: (search: string) => void
}) {
	const actions = {
		Reports: {
			actions: [
				{
					roles: ['staff', 'admin'],
					action: {
						icon: <ReceiptIndianRupee className="mr-2 h-4 w-4" />,
						text: 'Fees Collection Report',
						onSelect: () => {
							navigate('/reports/fees')
						},
						keywords: ['fees', 'reports'],
					},
				},
				{
					roles: ['admin'],
					action: {
						icon: <ReceiptIndianRupee className="mr-2 h-4 w-4" />,
						text: 'Financial Report',
						onSelect: () => {
							navigate('/reports/finances')
						},
						keywords: ['accounting', 'reports'],
					},
				},
			],
		},
		Accounting: {
			actions: [
				{
					action: {
						icon: <IndianRupee className="mr-2 h-4 w-4" />,
						text: 'Add Cash Payment',
						onSelect: () => {
							navigate('/accounting/payment')
						},
						keywords: ['accounting', 'payment'],
					},
				},
				{
					action: {
						icon: <IndianRupee className="mr-2 h-4 w-4" />,
						text: 'Add Receipt',
						onSelect: () => {
							navigate('/accounting/receipt')
						},
						keywords: ['accounting', 'receipt'],
					},
				},
				{
					roles: ['admin'],
					action: {
						icon: <PlusCircleIcon className="mr-2 h-4 w-4" />,
						text: 'Create Account groups/Ledgers',
						onSelect: () => {
							navigate('/accounting/chart-of-accounts')
							setSearch('')
						},
						keywords: ['accounting', 'ledger'],
					},
				},
				{
					action: {
						icon: <Network className="mr-2 h-4 w-4" />,
						text: 'Chart of Accounts',
						onSelect: () => {
							navigate('/accounting/chart-of-accounts')
							setSearch('')
						},
						keywords: ['accounting', 'ledger'],
					},
				},
			],
		},
		Students: {
			actions: [
				{
					action: {
						icon: <UserRoundPlus className="mr-2 h-4 w-4" />,
						text: 'Student Admission',
						onSelect: () => {
							navigate('/students/admission')
							setSearch('')
						},
						keywords: ['students'],
					},
				},
			],
		},
		Fees: {
			actions: [
				{
					roles: ['admin'],
					action: {
						icon: <BanknoteIcon className="mr-2 h-4 w-4" />,
						text: 'Create Fee Type',
						onSelect: () => {
							navigate('/settings/fee-types/create')
							setSearch('')
						},
						keywords: ['fees'],
					},
				},
				{
					action: {
						icon: <BanknoteIcon className="mr-2 h-4 w-4" />,
						text: 'List Fee Types',
						onSelect: () => {
							navigate('/settings/fee-types')
							setSearch('')
						},
						keywords: ['fees', 'list'],
					},
				},
				{
					roles: ['admin'],
					action: {
						icon: <HandCoins className="mr-2 h-4 w-4" />,
						text: 'Create Fee Structure (template)',
						onSelect: () => {
							navigate('/settings/fee-structures/create')
							setSearch('')
						},
						keywords: ['fees'],
					},
				},
				{
					action: {
						icon: <HandCoins className="mr-2 h-4 w-4" />,
						text: 'List Fee Structures',
						onSelect: () => {
							navigate('/settings/fee-structures')
							setSearch('')
						},
						keywords: ['fees', 'list'],
					},
				},
			],
		},
		Setup: {
			roles: ['admin'],
			actions: [
				{
					action: {
						icon: <CalendarIcon className="mr-2 h-4 w-4" />,
						text: 'Create Academic Year',
						onSelect: () => {
							navigate('/settings/academic-year/create')
							setSearch('')
						},
						keywords: ['setup'],
					},
				},
				{
					action: {
						icon: <CalendarIcon className="mr-2 h-4 w-4" />,
						text: 'Set Active Academic Year',
						onSelect: () => {
							navigate('/settings/academic-year/current')
							setSearch('')
						},
						keywords: ['setup'],
					},
				},
				{
					action: {
						icon: <UsersRound className="mr-2 h-4 w-4" />,
						text: 'Create Class',
						onSelect: () => {
							navigate('/students/classes/create')
							setSearch('')
						},
						keywords: ['setup'],
					},
				},
				{
					action: {
						icon: <UsersRound className="mr-2 h-4 w-4" />,
						text: 'List Classes',
						onSelect: () => {
							navigate('/students/classes')
							setSearch('')
						},
						keywords: ['classes', 'students', 'list'],
					},
				},
			],
		},
	}

	return actions
}
