export function getSearchType(search: string) {
	if (search.startsWith('s:')) {
		return 'student'
	}
	if (search.startsWith('cs:')) {
		return 'classStudent'
	}
	if (search.startsWith('u:')) {
		return 'user'
	}
	if (search.startsWith('t:')) {
		return 'teacher'
	}
	if (search.startsWith('p:')) {
		return 'parent'
	}
	if (search.startsWith('a:')) {
		return 'admin'
	}
	return false
}